export const CRIPTO_WITHDRAWAL_FEE_URL = "https://suporte.bity.com.br/pt-BR/articles/8822793-taxa-de-saques-cripto";
export const OTP_DISABLE_LOW_LEVEL_USERS_URL = "https://suporte.bity.com.br/pt-BR/articles/6967807-como-resetar-desativar-o-2fa-na-bity-nao-tendo-verificacao-de-conta";
export const OTP_DISABLE_HIGH_LEVEL_USERS_URL = "https://suporte.bity.com.br/pt-BR/articles/8394953-como-desabilitar-meu-2fa-no-bitybank";
export const SUPPORT_FAQ_CENTER_URL = "https://suporte.bity.com.br/pt-BR/collections/2727119-perguntas-frequentes";
export const SHOW_SUPPORT_CHAT_API_ERRORS = ["BLOCKED_ACCOUNT"]

export const serviceUpdateTypes = {
    EXPRESS_TRADE: 'EXPRESS_TRADE',
    ADVANCE_TRADE: 'ADVANCE_TRADE',
    INITIAL: 'INITIAL',
    USER: 'USER',
    ORDER: 'ORDER',
    TRANSACTIONS: 'TRANSACTIONS',
    BALANCE: 'BALANCE',
    LOGIN: 'LOGIN',
    PROFILE: 'PROFILE',
    EXECUTED_ORDERS: 'EXECUTED_ORDERS',
}

export const bityPhoenixNotifications = {
    FIAT_DEPOSIT_COMPLETE: "FIAT_DEPOSIT_COMPLETE",
    RECEIVING_COINS_DEPOSIT: "RECEIVING_COINS_DEPOSIT",
    COINS_DEPOSIT_COMPLETE: "COINS_DEPOSIT_COMPLETE",
    COINS_WITHDRAWAL_APPROVED: "COINS_WITHDRAWAL_APPROVED",
    COINS_WITHDRAWAL_COMPLETE: "COINS_WITHDRAWAL_COMPLETE",
    BALANCE_DEDUCTION: "BALANCE_DEDUCTION",
    FIAT_WITHDRAWAL_COMPLETE: "FIAT_WITHDRAWAL_COMPLETE",
    TRANSACTION_CANCELED: "TRANSACTION_CANCELED",
    FIAT_WITHDRAWAL_CANCELED: "FIAT_WITHDRAWAL_CANCELED",
    COINS_WITHDRAWAL_CANCELED: "COINS_WITHDRAWAL_CANCELED",
    
    BUY_ORDER_CREATED: "BUY_ORDER_CREATED",
    SELL_ORDER_CREATED: "SELL_ORDER_CREATED",
    ORDER_CANCELED: "ORDER_CANCELED",
    ORDER_FULLY_EXECUTED: "ORDER_FULLY_EXECUTED",
    ORDER_PARTIALLY_EXECUTED: "ORDER_PARTIALLY_EXECUTED",
    
    PAYMENT_CONCLUDED: "PAYMENT_CONCLUDED",
    PAYMENT_CANCELED: "PAYMENT_CANCELED",
    REFUND_CONCLUDED: "REFUND_CONCLUDED",
    CASHBACK_RECEIVED: "CASHBACK_RECEIVED",
    CARDS_UPDATED: "CARDS_UPDATED",
    
    KYC_APPROVED: "KYC_APPROVED",
    USER_PROFILE_UPDATED: "USER_PROFILE_UPDATED"
  };
  
  export const throttleMSInterval = 5000;
  export const throttleMsIntervalByCmd = {
    order_cancel: throttleMSInterval,
    login: throttleMSInterval,
    transactions: 3000,
    buy: throttleMSInterval,
    sell: throttleMSInterval,
    deposit_addr: throttleMSInterval,
    get_quote: throttleMSInterval,
    cancel_withdrawal: throttleMSInterval,
    reset_pass: throttleMSInterval,
    open_orders: throttleMSInterval,
    detailed_orderbook: throttleMSInterval,
  };

  export const tradeTypes = {
    converter: 'converter',
    express: 'express',
    orderbook: 'orderbook',
  }

  export const timeStampsInMiliseconds = {
    oneDay: 86400000,
    oneHour: 3600000,
    oneMinute: 60000,
  }