import { timeStampsInMiliseconds } from "./constants";

export const checkNoTransferOperationsWarning = (userId, coin, tradeType) => {
  const noTransferOperationsObjectLocalStorage = localStorage.getItem(
    "noTransferOperationsWarning"
  );
  const noTransferOperationsObject = noTransferOperationsObjectLocalStorage
    ? JSON.parse(noTransferOperationsObjectLocalStorage)
    : {};

  const userHasViewedWarning = noTransferOperationsObject[userId];
  const tradeTypeArray = userHasViewedWarning ? userHasViewedWarning[tradeType] : [];

 return tradeTypeArray ? tradeTypeArray.includes(coin) : false;
};

export const setNoTransferOperationsWarning = (userId, coin, tradeType) => {
  const noTransferOperationsObjectLocalStorage = localStorage.getItem(
    "noTransferOperationsWarning"
  );
  const noTransferOperationsObject = noTransferOperationsObjectLocalStorage
    ? JSON.parse(noTransferOperationsObjectLocalStorage)
    : {};
  
  let tradeTypeArray = noTransferOperationsObject[userId]
    ? noTransferOperationsObject[userId][tradeType]
    : [];
    tradeTypeArray = tradeTypeArray ? tradeTypeArray : [];
    tradeTypeArray.push(coin);
    noTransferOperationsObject[userId] = {
      ...noTransferOperationsObject[userId],
      [tradeType]: tradeTypeArray
    };
  localStorage.setItem(
    "noTransferOperationsWarning",
    JSON.stringify(noTransferOperationsObject)
  );
};

export const needToRevalidateCurrenciesData = () => {
  const revalidateCurrenciesDataTimeStamp = localStorage.getItem("revalidateCurrenciesDataTimestamp");
  const currentTimeStamp = new Date().getTime();
  const timeDifference = currentTimeStamp - Number(revalidateCurrenciesDataTimeStamp);
  return timeDifference > timeStampsInMiliseconds.oneMinute * 15;
}

export const setRevalidateCurrenciesDataTimeStamp = () => {
  localStorage.setItem("revalidateCurrenciesDataTimestamp", new Date().getTime());
}