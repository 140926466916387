import * as R from 'ramda';
import {
  put, takeLatest, call, select,
} from 'redux-saga/effects';

import { product } from '../../config';
import isNilOrEmpty from '../../utils/isNilOrEmpty';
import { isDemoAccount, checkIsRobotAccount } from '../../utils/accountUtils';
import { getDeclarationTypesList, getDeclarationTypeByDocPending } from '../../layout/Dialogs/UserDeclarationDialog/_constants';

import {
  updateCaptcha,
  addDialog,
  fetchService,
  removeCredentials,
  resolveValidation,
  resolveRegister,
  addOtpDialog,
  updateCredentials,
  startKeepAliveCheck,
  updateToken,
  addRenewConnectionDialog, closeRenewConnectionDialog,
  addResendEmailDialog, closeDialog, addSmsTokenDialog, closeSmsTokenDialog,
  removeIntercomHash,
  updateDataByType,
  changeUserDeclarationDialog,
  changeCreatePinDialog,
  resetOtpRequestedSuccess,
} from '../actions';

import { openNewTab } from '../../utils/generalUtils';
import { OTP_DISABLE_LOW_LEVEL_USERS_URL, OTP_DISABLE_HIGH_LEVEL_USERS_URL } from '../../utils/constants';

import getMetaMapId from '../../services/cbtcService/metamapId';
import { serviceUpdateTypes } from '../../utils/constants';

import { removeCelcoinAccount, removeCards} from '../user/actions';

import registerService from '../../services/cbtcService/register';

import {
  VERIFY_CAPTCHA,
  END_SESSION,
  REQUEST_VALIDATION,
  VALIDATE_ACC_SUCCEED,
  REQUEST_REGISTER,
  LOGIN,
  OTP_VALIDATE_LOGIN,
  REMOVE_CREDENTIALS,
  RESEND_VALIDATION,
  LOGIN_OTP,
  GET_PROFILE,
  FINISH_LOGIN,
  ADD_SESSION_EXPIRED_DIALOG,
  SEND_SMS_PHONE_VALIDATION,
  VALIDATE_PHONE_SMS_TOKEN,
  ADD_INTERCOM_HASH,
  PHONE_SMS_VALIDATION_SUCCEED,
  REMOVE_INTERCOM_HASH,
  UPDATE_INTERCOM_HASH,
  REMOVE_CELCOIN_REGISTER_DIALOG,
  RESET_OTP_REQUESTED,
} from './actionTypes';
import { updateProfile } from '../user/actions';
import { gtmLogin, setUserIdCookie } from '../../utils/dataLayer';
import { delay } from "redux-saga";

function* verifyCaptchaSaga({ captcha }) {
  yield put(updateCaptcha(captcha));
}

function* endSessionSaga() {
  try {
    yield put(addDialog({
      title: 'actions.endSession.title',
      availableChoices: [
        {
          label: 'common.no',
          actionToTake: '',
          color: 'secondary',
          variant: 'raised',
        },
        {
          label: 'common.yes',
          actionToTake: 'exit',
        },
      ],
      actionsToTake: {
        exit: removeCredentials,
      },
    }));
  } catch (error) {
    console.error('endSessionSaga', error);
  }
}


function* initValidationSaga({ token }) {
  yield put(fetchService({
    requestType: 'validate_acc',
    successAction: VALIDATE_ACC_SUCCEED,
    responseToUser: {
      onSuccess: 'dialog',
      onFail: 'dialog',
    },
    paramns: {
      form: {
        auth_token: token,
      },
      options: {
        cmd: 'validate_acc',
        method: 'POST',
      },
    },
  }));
}


function* validateAccSucceedSaga({ response = {} }) {
  try {
    const { success, message_cod } = response;

    if (message_cod === 'EMAIL_VALIDATED') {
      yield put(resolveValidation({
        success,
        resolved: true,
        message_cod,
      }));
    } else {
      yield put(resolveValidation({
        resolved: true,
        success: false,
        message_cod,
      }));
    }
  } catch (error) {
    yield put(resolveValidation({
      resolved: true,
      ...error,
    }));
    console.error('initValidationSaga', error);
  }
}

function* initRegisterSaga({ form }) {
  try {
    const cbRes = yield call(() => registerService(form));
    const { success, message_cod } = cbRes;

    yield put(resolveRegister({
      success,
      resolved: true,
    }));

    if (!success) {
      yield put(addDialog({
        title: `screens.register.${message_cod}`,
        availableChoices: [
          {
            label: 'common.close',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      }));
    }
  } catch (error) {
    yield put(resolveRegister({
      success: false,
      resolved: true,
    }));
    console.error('initRegisterSaga', error);
  }
}

function* loginWithURLToken({ payload }) {
  debugger;
  yield put({
    type: 'OTP_VALIDATE_LOGIN',
    response: { auth_token: payload.auth_token },
  });
  yield put(fetchService({
    requestType: 'get_profile',
    successAction: 'RESOLVE_OTP_VALIDATE_LOGIN',
    paramns: {
      form: {
        auth_token: payload.auth_token,
      },
      options: {
        cmd: 'get_profile',
        method: 'POST',
      },
    },
  }));
}

function* resolveOtpValidateLoginSaga({ type, ...profile }) {
  yield put(updateCredentials({ demoAccount: isDemoAccount(profile.email) }));
  yield put(updateProfile(profile));
  yield put(startKeepAliveCheck());
  setUserIdCookie();
  gtmLogin();
}

// 3
function* otpValidateLoginSaga({ response }) {
  const { otp_requested } = R.pick(['otp_requested'], response);

  if (Number(otp_requested)) {
    yield put(addOtpDialog({
      cmd: 'validate_otp',
      onOtpValidatedActionType: 'RESOLVE_OTP_VALIDATE_LOGIN',
      formValues: response,
    }));
  } else {
    yield put({ type: 'RESOLVE_OTP_VALIDATE_LOGIN', ...response });
  }
}

// 2
function* startOtpValidateLoginSaga({ response }) {
  const { success, auth_token } = R.pick(['success', 'auth_token'], response);

  if (success) {
    yield put(fetchService({
      requestType: 'get_profile',
      successAction: 'OTP_VALIDATE_PROFILE',
      paramns: {
        form: {
          auth_token,
        },
        options: {
          cmd: 'get_profile',
          method: 'POST',
        },
      },
    }));
  }
}

function* logoutSaga() {
  /*
    Takes the token aquired in login and use to call logout api that invalidates the token
    OBS: If it is the first time the user is logging in there is no auth_token, neither service.login
    so verify if it's the first login
  */
  const auth_token = yield select(state => (
    (state.appData.service.login ? state.appData.service.login.response.auth_token : null)));
  // const auth_token = yield select(state=>(state.appData.service.login.response.auth_token));
  if (auth_token) {
    yield put(fetchService({
      requestType: 'logout',
      paramns: {
        form: {
          auth_token,
        },
        options: {
          cmd: 'logout',
          method: 'POST',
        },
      },
    }));
  }
}

function* longinFailedSaga(responseData) {
  const { email } = responseData.form;
  const { message_cod } = responseData.response;
  if (message_cod === 'EMAIL_NOT_VALIDATED')
    yield put(addResendEmailDialog({ email }));
  else if(message_cod === 'PHONE_NOT_VALIDATED')
    yield put(addSmsTokenDialog({ payload: {...responseData.form}}));
  else {
    const isToShowSupportChat = R.prop('is_error_to_show_support_chat', responseData.response);
    yield put(addDialog({
      title: message_cod,
      renderComponent: isToShowSupportChat ? 'supportChat' : null,
      availableChoices: [
        {
          label: 'common.close',
          actionToTake: '',
          color: 'secondary',
          variant: 'raised',
        },
      ],
    }));
  }
}

//CALL INTERCOM HASH intercom_hash($token, $platform)
function* callIntercomHash() {
  const platform='WEB';
  yield put(fetchService({
    requestType: 'intercom',
    successAction: ADD_INTERCOM_HASH,
    failAction: '',
    paramns: {
      privateService: true,
      form:{platform, product},
      options: {
        cmd: 'intercom_hash',
        method: 'POST',
      },
    },
  }));
}

//STEP 1 LOGIN V1 and V2
function* loginSaga({ form }) {
  yield put(fetchService({
    requestType: 'login',
    successAction: LOGIN_OTP,
    failAction: 'LOGIN_FAILED',
    paramns: {
      form,
      options: {
        cmd: 'login',
        method: 'POST',
      },
    },
  }));
}

//STEP 2 LOGIN V2
function* loginOtp(properties) {
  const { response, form } = properties;
  const { success, otp_requested } = R.pick(['success', 'otp_requested' ], response);

  if (success) {
    if (Number(otp_requested)) {
      yield put(addOtpDialog({
        cmd: 'login',
        onOtpValidatedActionType: GET_PROFILE,
        formValues: form,
      }));
    } else {
      yield put({ type: GET_PROFILE, ...response });
    }
  }
}

//STEP 3 LOGIN V2
function* getProfileSaga(response) {
  const { success, auth_token } = R.pick(['success', 'auth_token'], response);

  if (success) {
    yield put(updateToken( response ));
    yield put(fetchService({
      requestType: 'get_profile',
      successAction: FINISH_LOGIN,
      paramns: {
        form: {
          auth_token,
        },
        options: {
          cmd: 'get_profile',
          method: 'POST',
        },
      },
    }));
  }
}

// STEP 4 LOGIN V2
function* finishLogin({ response }) {
  const isRenewConnectionDialogOpen = yield select(state => state.credentials.renewConnectionDialog.open);
  let userLoggedInWithSavedToken = false;
  if (isRenewConnectionDialogOpen) {
    userLoggedInWithSavedToken = true;
   yield put(closeRenewConnectionDialog());
  }

  const pendingSends = yield select(state => state.credentials.userDeclarationDialog.pendingSends);
  const declarationTypesList= getDeclarationTypesList(response.doc_pending);
  const hasPin = response?.has_pin;
  const taxId = response?.tax_id;
  const isRobotAccount = checkIsRobotAccount(taxId);

  if (declarationTypesList.length > 0 && isNilOrEmpty(pendingSends)) { 
    const declarationType = getDeclarationTypeByDocPending(declarationTypesList[0]);
    const newPendingSends = declarationTypesList;
    yield put(changeUserDeclarationDialog({ open: true, declarationType, pendingSends: newPendingSends }));
  }
  if (!hasPin && !isRobotAccount) {
    yield put(changeCreatePinDialog({ open: true }));
  }
  yield put(updateProfile(response));
  yield put(updateDataByType( {updateTypes: [serviceUpdateTypes.LOGIN, userLoggedInWithSavedToken ? serviceUpdateTypes.EXECUTED_ORDERS : '']}));
  yield put(updateCredentials({ demoAccount: isDemoAccount(response.email), userLoggedInWithSavedToken: userLoggedInWithSavedToken }));
  yield put(startKeepAliveCheck());
  setUserIdCookie();
  gtmLogin();
}

function* resendEmailValidation({ form }) {

  yield put(fetchService({
    requestType: 'resendEmailValidation',
    successAction: '',
    responseToUser: {
      onFail: 'dialog',
    },
    paramns: {
      form,
      options: {
        cmd: 'resend_validation',
        method: 'POST',
      },
    },
  }));
}


function* onSessionExpired() {

  const path = window.location.pathname;
  const dialogState = yield select(state => state.layout.dialog);
  const renewConnectionDialogStateState = yield select(state => state.credentials.renewConnectionDialog);
  const alreadyActivated = (dialogState.open && dialogState.title
    && dialogState.title === "SESSION_EXPIRED" || renewConnectionDialogStateState.open);

  if (!alreadyActivated && path && !path.includes("recoverPassword")){
    yield put (removeIntercomHash())
    yield put (removeCelcoinAccount())
    yield put (removeCards())
    yield put(closeDialog());
    yield put(addDialog({
      title: 'SESSION_EXPIRED',
      disableEscapeKeyDown: true,
      disableBackdropClick: true,
      availableChoices: [
        {
          label: 'common.exit',
          actionToTake: 'logout',
          color: 'secondary',
          variant: 'raised',
        },
        {
          label: 'common.continueConnected',
          actionToTake: 'continueConnected',
          color: 'secondary',
          variant: 'raised',
        },
      ],
      actionsToTake: {
       logout: removeCredentials,
       continueConnected: addRenewConnectionDialog
      }
    }));
  }

}

function* sendSmsPhoneValidation({ form }) {

  yield put(fetchService({
    requestType: 'sendSmsPhoneValidation',
    successAction: '',
    responseToUser: {
      onFail: 'dialog',
    },
    paramns: {
      form,
      options: {
        cmd: 'send_phone_validation',
        method: 'POST',
      },
    },
  }));
}

// Step 1 phone sms token validation
function* validatePhoneSmsToken({ form }) {

  yield put(fetchService({
    requestType: 'validatePhoneSmsToken',
    successAction: PHONE_SMS_VALIDATION_SUCCEED,
    responseToUser: {
      onFail: 'dialog',
    },
    paramns: {
      form,
      options: {
        cmd: 'validate_phone',
        method: 'POST',
      },
    },
  }));
}

// Step 2 phone sms token validation
function* validatePhoneSmsTokenSucceed(responseData) {
  const { form, response: { message_cod, auto_login } } = responseData;

  yield put(closeSmsTokenDialog());
  if (auto_login) {
      const responseData = {form, response: {...auto_login}}
      yield put({ type: LOGIN_OTP, ...responseData });
  }
  yield call(delay, 100);
  yield put(addDialog({
    title: message_cod,
    availableChoices: [
      {
        label: 'common.close',
        actionToTake: '',
        color: 'secondary',
        variant: 'raised',
      },
    ],
  }));

}

function* resetOtpRequestedSaga({ form }) {
  try {
  let urlToOpen = OTP_DISABLE_LOW_LEVEL_USERS_URL;
  let metamapId = yield select(state => state.user.profile.metamap_id);
  if (metamapId != null) {
    urlToOpen = OTP_DISABLE_HIGH_LEVEL_USERS_URL;
  } else {
    const response = yield call(() => getMetaMapId(form));
    metamapId = response.metamap_id;
    if (metamapId != null) {
      urlToOpen = OTP_DISABLE_HIGH_LEVEL_USERS_URL;
    }
  }
  openNewTab(urlToOpen);
  } catch (error) {
    openNewTab(OTP_DISABLE_LOW_LEVEL_USERS_URL);
  } finally {
    yield put(resetOtpRequestedSuccess())
  }
}


function* watchCredentials() {
  yield takeLatest(VERIFY_CAPTCHA, verifyCaptchaSaga);
  yield takeLatest(END_SESSION, endSessionSaga);
  yield takeLatest(REMOVE_INTERCOM_HASH, removeIntercomHash)
  yield takeLatest(REMOVE_CELCOIN_REGISTER_DIALOG, removeCelcoinAccount)
  yield takeLatest(REQUEST_VALIDATION, initValidationSaga);
  yield takeLatest(VALIDATE_ACC_SUCCEED, validateAccSucceedSaga);
  yield takeLatest(REQUEST_REGISTER, initRegisterSaga);
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest('LOGIN_FAILED', longinFailedSaga);
  yield takeLatest(REMOVE_CREDENTIALS, logoutSaga);
  yield takeLatest(OTP_VALIDATE_LOGIN, startOtpValidateLoginSaga);
  yield takeLatest('OTP_VALIDATE_PROFILE', otpValidateLoginSaga);
  yield takeLatest('RESOLVE_OTP_VALIDATE_LOGIN', resolveOtpValidateLoginSaga);
  yield takeLatest(LOGIN_OTP, loginOtp);
  yield takeLatest(GET_PROFILE, getProfileSaga);
  yield takeLatest(FINISH_LOGIN, finishLogin);
  yield takeLatest('LOGIN_URL_TOKEN', loginWithURLToken);
  yield takeLatest(RESEND_VALIDATION, resendEmailValidation);
  yield takeLatest(ADD_SESSION_EXPIRED_DIALOG, onSessionExpired);
  yield takeLatest(SEND_SMS_PHONE_VALIDATION, sendSmsPhoneValidation);
  yield takeLatest(VALIDATE_PHONE_SMS_TOKEN, validatePhoneSmsToken);
  yield takeLatest(PHONE_SMS_VALIDATION_SUCCEED, validatePhoneSmsTokenSucceed);
  yield takeLatest(UPDATE_INTERCOM_HASH, callIntercomHash);
  yield takeLatest(RESET_OTP_REQUESTED, resetOtpRequestedSaga);
}

export default watchCredentials;
